import {
  AccountData,
  ChainInfo,
  Keplr,
  OfflineDirectSigner,
} from '@keplr-wallet/types';
import { useChains } from 'components/Chain/hooks';
import cosmosChains from 'components/Wallet/cosmos/chains';
import { ReactNode, createContext, useEffect, useState } from 'react';
import { CosmosChainId } from 'types/emoney/Chain';

function uint8ArrayToHex(uint8Array: Uint8Array): string {
  return Buffer.from(uint8Array).toString('hex');
}

export interface CosmosProviderContextType {
  isInstalled: boolean;
  isConnected: boolean;
  connect: () => Promise<void>;
  disconnect: () => void;
  getAddresses: () => Promise<string[]>;
  signMessage: (
    message: string,
  ) => Promise<{ signature: string; publicKey: string }>;
  address: string;
  chainId: CosmosChainId;
  provider: Keplr | undefined;
  signer: OfflineDirectSigner | undefined;
}

export const CosmosProviderContext = createContext<
  CosmosProviderContextType | undefined
>(undefined);

interface CosmosProviderProviderProps {
  children: ReactNode;
}

export const CosmosContextProvider = ({
  children,
}: CosmosProviderProviderProps) => {
  const [isInstalled, setIsInstalled] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [address, setAddress] = useState<string>('');
  const [provider, setProvider] = useState<Keplr | undefined>(undefined);
  const [signer, setSigner] = useState<OfflineDirectSigner | undefined>(
    undefined,
  );
  const [chainId, setChainId] = useState<CosmosChainId>('noble-1');

  const chains = useChains();

  const getAccounts = async () => {
    if (!signer) return;
    const accounts = await signer.getAccounts();
    setAddress(accounts[0].address);
  };

  useEffect(() => {
    const { keplr } = window;
    if (keplr) {
      setIsInstalled(true);
      setProvider(keplr);
    }
  }, []);

  useEffect(() => {
    if (!chains?.length) return;

    const nobleChain = chains.find((c) => c?.kind === 'cosmos');
    const chainId = nobleChain?.chainId as CosmosChainId;

    setChainId(chainId);
  }, [chains]);

  useEffect(() => {
    if (!chainId) return;
    if (!provider) return;

    const offlineSigner = provider.getOfflineSigner(
      chainId,
    ) as OfflineDirectSigner;

    setSigner(offlineSigner);
    // Listen for account changes.
    window.addEventListener('keplr_keystorechange', getAccounts);

    return () => {
      window.removeEventListener('keplr_keystorechange', getAccounts);
    };
  }, [provider, chainId, address]);

  const connect = async () => {
    try {
      if (!chainId) {
        throw new Error('Chain ID missing');
      }
      await enable(chainId);
      await getAccounts();

      const connection = await provider?.getKey(chainId);

      setIsConnected(!!connection);
    } catch (error) {
      throw new Error((error as Error)?.message);
    }
  };

  const disconnect = () => {
    setIsConnected(false);
    setAddress('');

    if (provider && provider.disable) {
      provider.disable();
    }
  };

  const getAddresses = async (): Promise<string[]> => {
    if (signer) {
      const accounts: readonly AccountData[] = await signer.getAccounts();
      return accounts.map((account) => account.address);
    }
    return [];
  };

  const signMessage = async (message: string) => {
    const address = (await getAddresses())[0];
    const sig = await provider?.signArbitrary(chainId, address, message);

    // Verify signature
    if (sig?.signature && sig?.pub_key?.value) {
      const signatureArray = new Uint8Array(
        Buffer.from(sig.signature, 'base64'),
      );
      const publicKeyArray = new Uint8Array(
        Buffer.from(sig.pub_key.value, 'base64'),
      );

      const signature = `0x${uint8ArrayToHex(signatureArray)}`;
      const publicKey = `0x${uint8ArrayToHex(publicKeyArray)}`;
      return { signature, publicKey };
    }
    throw new Error('Unable to produce signature in Keplr');
  };

  const enable = async (chainId: CosmosChainId): Promise<void> => {
    const chainInfo = cosmosChains.find(
      (c) => c.chainId === chainId,
    ) as ChainInfo;

    try {
      await provider?.experimentalSuggestChain(chainInfo);
    } catch (error) {
      throw new Error((error as Error)?.message);
    }
    try {
      await provider?.enable(chainId);
    } catch (error) {
      throw new Error((error as Error)?.message);
    }
  };

  return (
    <CosmosProviderContext.Provider
      value={{
        isInstalled,
        isConnected,
        connect,
        disconnect,
        getAddresses,
        signMessage,
        address,
        chainId,
        provider,
        signer,
      }}
    >
      {children}
    </CosmosProviderContext.Provider>
  );
};
export default CosmosContextProvider;
