'use client';

import { useChains } from 'components/Chain/hooks';
import { useSharedAppContext } from 'providers/AppConfig';
import React, { useMemo } from 'react';
import { useLogger } from 'services/logger';
import { WagmiProvider, cookieToInitialState } from 'wagmi';
import evmChains, { ethereum, ethereumLocal, sepolia } from './chains';
import { config as getWagmiConfig } from './config';
import { ViemChains } from './types';

const EvmProvider = ({ children }: { children: React.ReactNode }) => {
  const { environment, cookies } = useSharedAppContext();
  const chains = useChains('evm');
  const { logMessage } = useLogger();

  const wagmiChains = useMemo(() => {
    if (!chains.length) {
      // Fallback to initialize the WagmiProvider.
      if (environment === 'dev') {
        return [ethereumLocal] as ViemChains;
      }
      if (environment === 'staging' || environment === 'sandbox') {
        return [sepolia] as ViemChains;
      }
      return [ethereum] as ViemChains;
    }
    return evmChains.filter((evmChain) =>
      chains?.some((chain) => Number(chain?.chainId) === evmChain.id),
    ) as unknown as ViemChains;
  }, [chains]);

  const wagmiConfig = useMemo(() => {
    return getWagmiConfig(environment, wagmiChains);
  }, [environment, wagmiChains]);

  const wagmiInitialState = useMemo(() => {
    try {
      return cookieToInitialState(wagmiConfig, cookies);
    } catch (error) {
      logMessage({
        message:
          'Failed to parse wagmi cookie state, ' +
          error +
          '. Cookie: ' +
          cookies,
        severity: 'warning',
      });
      return undefined;
    }
  }, [wagmiConfig, cookies]);

  return (
    <WagmiProvider
      config={wagmiConfig}
      initialState={wagmiInitialState}
      reconnectOnMount
    >
      {children}
    </WagmiProvider>
  );
};
export default EvmProvider;
