import { combineReducers } from 'redux';
import app from './App/slice';
import confirm from './Confirm/reducer';
import invite from './Invite/slice';
import login from './Login/reducer';
import profile from './Profile/reducer';
import reset from './Reset/reducer';
import signup from './Signup/reducer';
import { IamReduxStore } from './types';

export default combineReducers<IamReduxStore>({
  app,
  invite,
  login,
  signup /** TODO: remove with isFeatureReactQuerySignup */,
  profile,
  confirm,
  reset,
});
